import { Component, effect, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { McEntityService, McUtilityService } from '@miticon-ui/mc-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-mc-entity-basic-info-tab',
  templateUrl: './mc-entity-basic-info-tab.component.html',
  styleUrl: './mc-entity-basic-info-tab.component.scss',
})
export class McEntityBasicInfoTabComponent implements OnInit {

  paramSubscription!: Subscription;
  entityObject!: any;
  entityId: any

  constructor(private activatedRoute: ActivatedRoute,
              private utilityService: McUtilityService,
              private entityService: McEntityService) {

    effect(() => {
      this.entityObject = this.entityService.currentMcEntity();
    });
  }

  ngOnInit() {
    this.paramSubscription = this.activatedRoute.paramMap.subscribe(data => {
      data.get('id') ? this.entityId = data.get('id') : this.entityId = this.utilityService.getPropertyFromToken('entity_id');
    });
  }

}
