import { Component, OnInit } from '@angular/core';
import { EntityInvitation, EntityInvitationService, SortCriteriaList } from '@miticon-ui/mc-core';
import { ICON_INVITATIONS } from '../../../../assets/media/svg_icons/icon-invitations';
import { MkFilterOutput, MkMatMenuItem, MkMatTableMenuAction, MkTableConfig } from '@miticon-ui/mc-components';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { McSendInvitationDialogComponent } from '../mc-send-invitation-dialog/mc-send-invitation-dialog.component';
import { McGod } from '@miticon-ui/mc-core';
@Component({
  selector: 'lib-mc-entity-invitations-tab',
  templateUrl: './mc-entity-invitations-tab.component.html',
  styleUrl: './mc-entity-invitations-tab.component.scss'
})
export class McEntityInvitationsTabComponent  implements OnInit {


  iconInvitations = ICON_INVITATIONS;
  items: EntityInvitation[] = [];
  tableConfig = new MkTableConfig();
  isLoading = false;
  pageNumber: number = 0;
  pageSize: number = 5;
  totalItemsCount!: number;
  tableActionItems: MkMatMenuItem[] = [
    {
      title: this.tS.instant("cc.common.edit.cancel"),
      actionCd: 'Cancel',
      matIcon: 'cancel',
      permission: McGod.PERM_MC_ENTITY_CAN_UPDATE
    }
  ]

  constructor(private toastr: ToastrService,
              private tS: TranslateService,
              private entityInvitationService: EntityInvitationService,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.initTableConfig();
    this.actLoad(this.pageNumber, this.pageSize);
  }

  actLoad(pageNumber: number, pageSize: number): void {
    this.isLoading = true;
    this.pageNumber = pageNumber;
    this.pageSize = pageSize;
    const sortCriteriaList = new SortCriteriaList();

    this.entityInvitationService.getByEntityId(this.pageNumber, this.pageSize, sortCriteriaList).subscribe((res) => {
      if (res) {
        this.isLoading = false;
        this.totalItemsCount = res.totalElements;
        this.items = res.content.map((object: any) => EntityInvitation.createFromJson(object));
      }
    });

  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(this.tS.instant("cc.entity.profile.invitation-code"), 'invitationCode');
    this.tableConfig.addColumnStandard(this.tS.instant("cc.common.edit.email"), 'email');
    this.tableConfig.addColumnStandard(this.tS.instant("cc.common.view.entity"), 'invitedEntityName');
    this.tableConfig.addColumnSvgIcon(this.tS.instant("cc.common.view.status"), 'getStatusLabel()');
  }

  onTableAction($evet: MkMatTableMenuAction) {
    if ($evet.action.actionCd === EntityInvitation.ACTION_CANCEL) {
      this.entityInvitationService.cancelEntityInvitation($evet.item.id).subscribe({
        next: () => {
          this.toastr.success(this.tS.instant('cc.entity.invitations-cancel-success'));
          this.actLoad(this.pageNumber, this.pageSize);
        },
        error: (err) => {
          this.toastr.error(err.error.message)
        }
      });
    }
  }

  onFilterChanged(filterOutput: MkFilterOutput) {
    this.actLoad(filterOutput.pageEvent.pageIndex, filterOutput.pageEvent.pageSize);
  }


  openSendInvitationDialog() {
    this.dialog.open(McSendInvitationDialogComponent, {
      height: '700px',
      width: '700px',
      autoFocus: false,
      restoreFocus: false,
    })
  }
}
