import {Component, OnInit} from '@angular/core';
import { McEntityService, } from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import { MccStepperComponent } from '@miticon-ui/mc-components';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'mc-entity-profile',
  templateUrl: './mc-entity-profile.component.html',
  styleUrl: './mc-entity-profile.component.scss',
  providers: [{provide: CdkStepper, useExisting: MccStepperComponent}]
})
export class  McEntityProfileComponent implements OnInit {

  showLoader: boolean;
  entityId: any;
  mcEntity!: any;

  constructor(private activatedRoute: ActivatedRoute,
              private entityService: McEntityService,
              private toastr: ToastrService) {}

  ngOnInit() {
    this.entityId = this.activatedRoute.snapshot.params['id'];
    this.getEntityFromDb();
 }


  private getEntityFromDb() {
    this.showLoader = true;
      if(this.entityId) {
        this.entityService.getEntityBasicInfoById(this.entityId).subscribe({
        next: (response) => {
          this.mcEntity = response;
          this.entityService.currentMcEntity.set(response);
          this.showLoader = false;
        },
        error: (error) => {
          this.showLoader = false;
          this.toastr.error(error.error.message);
        }
      });
      }
  }

  onSuccessEditBasicInfo(event: boolean) {
    if (event) { this.getEntityFromDb(); }
  }

}
