import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {McEntityProfileComponent} from './mc-entity-profile.component';
import {AuthGuard, McPermissionGuard} from '@miticon-ui/mc-core';
import { McEntityBasicInfoTabComponent } from './components/mc-entity-basic-info-tab/mc-entity-basic-info-tab.component';
import { McEntityBankAccountsTabComponent } from './components/mc-entity-bank-accounts-tab/mc-entity-bank-accounts-tab.component';
import { McEntityAttributesTabComponent } from './components/mc-entity-attributes-tab/mc-entity-attributes-tab.component';
import { McEntitySettingsTabComponent } from './components/mc-entity-settings-tab/mc-entity-settings-tab.component';
import { McEntityInvitationsTabComponent } from './components/mc-entity-invitations-tab/mc-entity-invitations-tab.component';


const routes: Routes = [
  {
    path: '',
    component: McEntityProfileComponent,
    canActivate: [AuthGuard, McPermissionGuard],
    children: [
      {
        path: 'info',
        component: McEntityBasicInfoTabComponent
      },
      {
        path: 'bank-accounts',
        component: McEntityBankAccountsTabComponent
      },
      {
        path: 'attributes',
        component: McEntityAttributesTabComponent
      },
      {
        path: 'settings',
        component: McEntitySettingsTabComponent
      },
      {
        path: 'invitations',
        component: McEntityInvitationsTabComponent
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class McEntityProfileRoutingModule {
}
