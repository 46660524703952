import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  BaseDirective,
  EbFactoring,
  McBoolean, McCurrencyService,
  McDateUtils, McEntity2, McEntity2Service,
  McForm,
  McGod, McValueLabelList, SortCriteriaList
} from '@miticon-ui/mc-core';
import {takeUntil} from "rxjs/operators";


@Component({
  selector: 'app-shared-factoring-add-edit-part',
  templateUrl: './app-shared-factoring-add-edit-part.component.html',
})
export class AppSharedFactoringAddEditPartComponent extends BaseDirective implements OnInit, OnChanges {

  mcForm: McForm;
  yesNoVlm!: Map<string, string>;
  returnableClaimsVlm!: Map<string, string>;
  today = McDateUtils.getNowTimestamp();
  mcGod = McGod.getInstance();
  childEntity = new McEntity2();
  sendToBlacklistAfterBounceRequiredFlg: boolean = false;
  removeMemberWithoutOpenDebtFromBlacklistFlg: boolean;
  ebFactoringClone = new EbFactoring();
  currencyVll = new McValueLabelList();
  currencyCode!: string;

  /*Variables*/
  @Input() ebFactoringId!: number;
  @Input() entityClone!: McEntity2;
  @Input() idEntityForCloning!: number;
  @Input() ebFactoring = new EbFactoring();
  @Input() idChildEntity!: number;
  @Input() showPageTitleFlg = false;
  @Input() showCancelButtonFlg = false;
  @Input() showSaveButtonFlg = false;
  @Input() showDeleteButtonFlg = false;
  @Input() isVisible!: McBoolean;
  @Input() contractType!: string;
  @Input() isClonedContract!: boolean;

  @Output() eventSaved = new EventEmitter<EbFactoring>();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventDeleted = new EventEmitter();
  @Output() eventPrevious = new EventEmitter<boolean>();
  errorMessage!: string;
  isCumulativeLimitFilled!: boolean;

  /*Constructor*/
  constructor(private mcEntity2Service: McEntity2Service, private  mcCurrencyService: McCurrencyService) {
    super();
    this.mcForm = new McForm();
  }

  /*On init*/
  ngOnInit() {
    // TODO Need to refactor creating and cloning flow
    this.isCumulativeLimitFilled = true;
    this.actLoad();

    this.returnableClaimsVlm = new Map<string, string>();
    this.returnableClaimsVlm.set('subtract', 'Subtract ....');
    this.returnableClaimsVlm.set('subtract_next_payout', 'subtract next payout');
    this.returnableClaimsVlm.set('issue', 'Issue.......');

    // setting VLM for Yes/No Radio Buttons
    this.yesNoVlm = new Map<string, string>();
    this.yesNoVlm.set('yes', this.mcGod.t('cc.common.edit.yes'));
    this.yesNoVlm.set('no', this.mcGod.t('cc.common.edit.no'));

    const sortCriteriaList = new SortCriteriaList();
    this.mcCurrencyService.getAll(0, 50, sortCriteriaList)
      .pipe(takeUntil(this.destroyed$)).subscribe((response) => {
      response.content.forEach((item: { id: any; code: string; }) => {
        this.currencyVll.add(item.id , item.code);
      });
    });


  }


  onCurrencyChanged(value: string) {
    this.currencyVll.items.forEach(item => {
      if (item.value === Number(value)) {
        this.currencyCode = item.label;
      }
    });
  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ebFactoringId']) {
      if ((changes['ebFactoringId'].previousValue !== changes['ebFactoringId'].currentValue) && (changes['ebFactoringId'].currentValue > 0)) {
        this.actLoad();
      }
    }
  }

  // ---------------------------------------------------------------------
  public actLoad(): void {
    if (!this.isClonedContract) {
      this.childEntity.loadById(this.idChildEntity);
      this.ebFactoring = new EbFactoring();
    } else {
      this.ebFactoring.loadById(this.ebFactoringId, () => {
        this.fillCloneContract();
      });
    }

  }

  fillCloneContract() {
    this.ebFactoringClone = new EbFactoring();
    this.ebFactoringClone.contractCancellationPeriod = this.ebFactoring.contractCancellationPeriod;
    this.ebFactoringClone.maximalNumberOfFailedWithdrawals = this.ebFactoring.maximalNumberOfFailedWithdrawals;
    this.ebFactoringClone.softLimitPerTransaction = this.ebFactoring.softLimitPerTransaction;
    this.ebFactoringClone.softLimitPerSepaRun = this.ebFactoring.softLimitPerSepaRun;
    this.ebFactoringClone.hardLimitForCurrentEntity = this.ebFactoring.hardLimitForCurrentEntity;
    this.ebFactoringClone.hardLimitForCurrentEntityDays = this.ebFactoring.hardLimitForCurrentEntityDays;
    this.ebFactoringClone.hardCumulativeLimit = this.ebFactoring.hardCumulativeLimit;
    this.ebFactoringClone.hardCumulativeLimitDays = this.ebFactoring.hardCumulativeLimitDays;
    this.ebFactoringClone.monthsAllowedPastCount = this.ebFactoring.monthsAllowedPastCount;
    this.ebFactoringClone.monthsAllowedFutureCount = this.ebFactoring.monthsAllowedFutureCount;
    this.ebFactoringClone.typeCd = this.ebFactoring.typeCd;
    this.ebFactoringClone.idMcCurrency = this.ebFactoring.idMcCurrency;
    if (this.ebFactoringClone.typeCd === EbFactoring.TYPE_CD_FACTORING) {
      this.ebFactoringClone.factoredClaimFeePerTransaction = this.ebFactoring.factoredClaimFeePerTransaction;
      this.ebFactoringClone.nonFactoredClaimFeePerTransaction = this.ebFactoring.nonFactoredClaimFeePerTransaction;
      this.ebFactoringClone.daysForInitialPayoutForFactoredTransactions = this.ebFactoring.daysForInitialPayoutForFactoredTransactions;
      this.ebFactoringClone.daysForInitialPayoutForNonFactoredTransactions = this.ebFactoring.daysForInitialPayoutForNonFactoredTransactions;
    } else {
      this.ebFactoringClone.feePerTransaction = this.ebFactoring.feePerTransaction;
      this.ebFactoringClone.feePerTransactionTypeCd = this.ebFactoring.feePerTransactionTypeCd;
      this.ebFactoringClone.feePerBatch = this.ebFactoring.feePerBatch;
      this.ebFactoringClone.percentageForInitialPayoutNonFactoring = this.ebFactoring.percentageForInitialPayoutNonFactoring;
      this.ebFactoringClone.noDaysForInitialPayoutNonFactoring = this.ebFactoring.noDaysForInitialPayoutNonFactoring;
      this.ebFactoringClone.noDaysForTheRestToBePaidNonFactoring = this.ebFactoring.noDaysForTheRestToBePaidNonFactoring;
    }

    this.ebFactoringClone.sendClaimsToBlacklistFlg = this.ebFactoring.sendClaimsToBlacklistFlg;
    this.ebFactoringClone.removeMemberWithoutOpenDebtFromBlFlg = this.ebFactoring.removeMemberWithoutOpenDebtFromBlFlg;

    if (this.ebFactoringClone.sendClaimsToBlacklistFlg) {
      this.sendToBlacklistAfterBounceRequiredFlg = true;
      this.mcForm.getControl('ctrlSendClaimsToBlacklist')?.get('value')?.setValue('yes');
      this.ebFactoringClone.blacklistTriggerSoftBounceCount = this.ebFactoring.blacklistTriggerSoftBounceCount;
      this.ebFactoringClone.blacklistTriggerHardBounceCount = this.ebFactoring.blacklistTriggerHardBounceCount;
    }
  }

  clearForm() {
    this.mcForm.getControl('ctrlFactoringContractName')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlLimitPerConsumer')?.get('value')?.setValue(null);
    this.mcForm.getControl('ctrlLimitNumberOfFailedWithdrawals')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlTotalMonthlyLimit')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlFactoringNetFee')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlDelayDaysForOverLimit')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlAmountPaidAfterWithdrawalProcessingDays')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlContractStartDate')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlContractEndDate')?.get('value')?.setValue('');
    this.mcForm.getControl('ctrlContractCancellationPeriod')?.get('value')?.setValue('');
  }

  actCancel() {
    this.eventCanceled.emit();
    this.clearForm();
  }


  actSaveItem() {
    const contractName = this.mcForm.getValue('ctrlFactoringContractName').trim();
    if (this.mcForm.isValid()) {
      this.ebFactoring.statusCd = EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
      this.ebFactoring.factoringContractCd = this.contractType;
      this.ebFactoring.idChildMcEntity = this.idChildEntity;
      this.ebFactoring.idParentMcEntity = McGod.getLoggedEntityIdFromToken();
      this.ebFactoring.factoringContractName = contractName;
      this.ebFactoring.contractStartDate = this.mcForm.getValue('ctrlContractStartDate');
      this.ebFactoring.contractEndDate = this.mcForm.getValue('ctrlContractEndDate');
      this.ebFactoring.contractCancellationPeriod = this.mcForm.getValue('ctrlContractCancellationPeriod');
      this.ebFactoring.maximalNumberOfFailedWithdrawals = this.mcForm.getValue('ctrlMaximalNumberOfFailedWithdrawals');
      this.ebFactoring.softLimitPerTransaction = this.mcForm.getValue('ctrlPerTransactionLimitValue');
      this.ebFactoring.softLimitPerSepaRun = this.mcForm.getValue('ctrlPerSepaRunLimitValue');
      this.ebFactoring.hardLimitForCurrentEntity = this.mcForm.getValue('ctrlCurrentEntityLimitValue');
      this.ebFactoring.hardLimitForCurrentEntityDays = this.mcForm.getValue('ctrlHardCurrentEntityLimitDays');
      this.ebFactoring.hardCumulativeLimit = this.mcForm.getValue('ctrlCumulativePerEntityAndSubentitiesLimitValue');
      this.ebFactoring.hardCumulativeLimitDays = this.mcForm.getValue('ctrlHardCumulativeEntityLimitDays');
      this.ebFactoring.monthsAllowedPastCount = this.mcForm.getValue('ctrlAllowedToImportClaimsPast');
      this.ebFactoring.monthsAllowedFutureCount = this.mcForm.getValue('ctrlAllowedToImportClaimsFuture');
      this.ebFactoring.typeCd = this.contractType === EbFactoring.TYPE_CD_FACTORING ? EbFactoring.TYPE_CD_FACTORING : EbFactoring.TYPE_CD_SERVICE;
      this.ebFactoring.idMcCurrency = this.mcForm.getValue('selCurrency');
      if (this.contractType === EbFactoring.TYPE_CD_FACTORING) {
        this.ebFactoring.factoredClaimFeePerTransaction = this.mcForm.getValue('ctrlFactoredClaimsFeeValue');
        this.ebFactoring.nonFactoredClaimFeePerTransaction = this.mcForm.getValue('ctrlNonFactoredClaimsFeeValue');
        this.ebFactoring.daysForInitialPayoutForFactoredTransactions = this.mcForm.getValue('ctrFactoredTransactionsDays');
        this.ebFactoring.daysForInitialPayoutForNonFactoredTransactions = this.mcForm.getValue('ctrNonFactoredTransactionsDays');
      } else {
        this.ebFactoring.feePerTransaction = this.mcForm.getValue('ctrlNonFactoringPerTransactionValue');
        this.ebFactoring.feePerTransactionTypeCd = this.mcForm.getValue('ctrlNonFactoringPerTransactionType') === 'percentage'
          ? EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE : EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT;
        this.ebFactoring.feePerBatch = this.mcForm.getValue('ctrlNonFactoringPerBatchValue');
        this.ebFactoring.percentageForInitialPayoutNonFactoring = this.mcForm.getValue('ctrlSplitValue');
        this.ebFactoring.noDaysForInitialPayoutNonFactoring = this.mcForm.getValue('ctrlToBePaidOutDaysAfterWithdrawal');
        this.ebFactoring.noDaysForTheRestToBePaidNonFactoring = this.mcForm.getValue('ctrlTheRestToBePaid');
      }

      this.ebFactoring.sendClaimsToBlacklistFlg = this.mcForm.getValue('ctrlSendClaimsToBlacklist') === 'yes';
      if (this.ebFactoring.sendClaimsToBlacklistFlg) {
        this.ebFactoring.blacklistTriggerSoftBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterSoft');
        this.ebFactoring.blacklistTriggerHardBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterHard');
      }

      this.ebFactoring.removeMemberWithoutOpenDebtFromBlFlg = this.mcForm.getValue('ctrlRemoveMemberFromBlacklist') === 'yes' && this.ebFactoring.sendClaimsToBlacklistFlg

      const maximalNumberOfFailedWithdrawals = this.mcForm.getValue('ctrlMaximalNumberOfFailedWithdrawals');
      const perTransactionLimit = Number(this.mcForm.getValue('ctrlPerTransactionLimitValue'));
      const perSepaRunLimit = Number(this.mcForm.getValue('ctrlPerSepaRunLimitValue'));
      const perCurrentEntityLimit = Number(this.mcForm.getValue('ctrlCurrentEntityLimitValue'));
      const cumulativeLimit = Number(this.mcForm.getValue('ctrlCumulativePerEntityAndSubentitiesLimitValue'));
      const softBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterSoft');
      const hardBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterHard');
      if (this.mcForm.getValue('ctrlContractEndDate') && (this.ebFactoring.contractEndDate < this.ebFactoring.contractStartDate)) {
        this.mcForm.getControl('ctrlContractEndDate')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlContractEndDate')?.get('errorMsg')?.setValue(this.mcGod.t('cc.out-factoring.end-date-need-to-be-after-start-date'));
      }
      if ((perSepaRunLimit && perTransactionLimit) && (perSepaRunLimit < perTransactionLimit)) {
        this.mcForm.getControl('ctrlPerSepaRunLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlPerSepaRunLimitValue')?.get('errorMsg')?.setValue(this.mcGod.t('cc.contract.per-sepa-run-limit-needs-to-be-higher-than-sepa-per-transaction-limit'));
      }
      if ((perCurrentEntityLimit && perTransactionLimit) && (perCurrentEntityLimit < perTransactionLimit)) {
        this.mcForm.getControl('ctrlCurrentEntityLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlCurrentEntityLimitValue')?.get('errorMsg')?.setValue(McGod.t('cc.contract.per-current-entity-limit-needs-to-be-higher-than-per-transaction-limit'));
      }
      if ((perCurrentEntityLimit && cumulativeLimit) && (cumulativeLimit < perCurrentEntityLimit)) {
        this.mcForm.getControl('ctrlCumulativePerEntityAndSubentitiesLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlCumulativePerEntityAndSubentitiesLimitValue')?.get('errorMsg')?.setValue(McGod.t('cc.contract.cumulative-limit-needs-to-be-higher-than-per-transaction-limit'));
      }
      if (softBounceCount && maximalNumberOfFailedWithdrawals && (softBounceCount > maximalNumberOfFailedWithdrawals)) {
        this.mcForm.getControl('ctrlSentToBlacklistAfterSoft')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlSentToBlacklistAfterSoft')?.get('errorMsg')?.setValue(McGod.t('cc.contract.number-of-soft-bounce-failed-withdrawals-has-to-be-smaller-than-maximal-number-of-failed-withdrawals'));
      }
      if (hardBounceCount && maximalNumberOfFailedWithdrawals && (hardBounceCount > maximalNumberOfFailedWithdrawals)) {
        this.mcForm.getControl('ctrlSentToBlacklistAfterHard')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlSentToBlacklistAfterHard')?.get('errorMsg')?.setValue(McGod.t('cc.contract.number-of-hard-bounce-failed-withdrawals-has-to-be-smaller-than-maximal-number-of-failed-withdrawals'));
      }
      this.ebFactoring.save(() => {
        this.eventSaved.emit(this.ebFactoring);
      }, () => {
        if (this.ebFactoring.apiErrorMessage === this.mcGod.t('cc.out-factoring.factoring-with-this-name-already-exists')) {
          this.mcForm.getControl('ctrlFactoringContractName')?.get('errorFlg')?.setValue(true);
          this.mcForm.getControl('ctrlFactoringContractName')?.get('errorMsg')?.setValue(McGod.t('cc.contract.contract-with-this-name-already-exists'));
          this.errorMessage = '';}
      });
    }
  }


  // ---------------------------------------------------------------------
  actDeleteItem() {
    if (this.ebFactoring.exists()) {
      this.ebFactoring.delete(() => {
        this.eventDeleted.emit();
      });
    }
  }

  actBack() {
    this.eventPrevious.emit(true);
  }

  sendClaimsToBlacklistRadioValueChanged(value: string) {
    this.sendToBlacklistAfterBounceRequiredFlg = (value === 'yes');
  }

  removeMemberWithoutOpenDebtFromBlacklistValueChanged(value: string){
    this.removeMemberWithoutOpenDebtFromBlacklistFlg = (this.sendToBlacklistAfterBounceRequiredFlg && value == 'yes')
  }

  onCumulativeLimitValueChanged(value: string) {
    this.isCumulativeLimitFilled = value !== '';
  }

  actSaveClonedItem() {
    const contractName = this.mcForm.getValue('ctrlFactoringContractName').trim();
    if (this.mcForm.isValid()) {
      this.ebFactoringClone.statusCd = EbFactoring.STATUS_CD_PENDING_ACCEPTANCE;
      this.ebFactoringClone.factoringContractCd = this.contractType;
      this.ebFactoringClone.idChildMcEntity = this.entityClone.id;
      this.ebFactoringClone.idParentMcEntity = McGod.getLoggedEntityIdFromToken();
      this.ebFactoringClone.factoringContractName = contractName;
      this.ebFactoringClone.contractStartDate = this.mcForm.getValue('ctrlContractStartDate');
      this.ebFactoringClone.contractEndDate = this.mcForm.getValue('ctrlContractEndDate');
      this.ebFactoringClone.contractCancellationPeriod = this.mcForm.getValue('ctrlContractCancellationPeriod');
      this.ebFactoringClone.maximalNumberOfFailedWithdrawals = this.mcForm.getValue('ctrlMaximalNumberOfFailedWithdrawals');
      this.ebFactoringClone.softLimitPerTransaction = this.mcForm.getValue('ctrlPerTransactionLimitValue');
      this.ebFactoringClone.softLimitPerSepaRun = this.mcForm.getValue('ctrlPerSepaRunLimitValue');
      this.ebFactoringClone.hardLimitForCurrentEntity = this.mcForm.getValue('ctrlCurrentEntityLimitValue');
      this.ebFactoringClone.hardLimitForCurrentEntityDays = this.mcForm.getValue('ctrlHardCurrentEntityLimitDays');
      this.ebFactoringClone.hardCumulativeLimit = this.mcForm.getValue('ctrlCumulativePerEntityAndSubentitiesLimitValue');
      this.ebFactoringClone.hardCumulativeLimitDays = this.mcForm.getValue('ctrlHardCumulativeEntityLimitDays');
      this.ebFactoringClone.monthsAllowedPastCount = this.mcForm.getValue('ctrlAllowedToImportClaimsPast');
      this.ebFactoringClone.monthsAllowedFutureCount = this.mcForm.getValue('ctrlAllowedToImportClaimsFuture');
      this.ebFactoringClone.typeCd = this.contractType === EbFactoring.TYPE_CD_FACTORING ? EbFactoring.TYPE_CD_FACTORING : EbFactoring.TYPE_CD_SERVICE;
      this.ebFactoringClone.idMcCurrency = this.mcForm.getValue('selCurrency');
      if (this.contractType === EbFactoring.TYPE_CD_FACTORING) {
        this.ebFactoringClone.factoredClaimFeePerTransaction = this.mcForm.getValue('ctrlFactoredClaimsFeeValue');
        this.ebFactoringClone.nonFactoredClaimFeePerTransaction = this.mcForm.getValue('ctrlNonFactoredClaimsFeeValue');
        this.ebFactoringClone.daysForInitialPayoutForFactoredTransactions = this.mcForm.getValue('ctrFactoredTransactionsDays');
        this.ebFactoringClone.daysForInitialPayoutForNonFactoredTransactions = this.mcForm.getValue('ctrNonFactoredTransactionsDays');
      } else {
        this.ebFactoringClone.feePerTransaction = this.mcForm.getValue('ctrlNonFactoringPerTransactionValue');
        this.ebFactoringClone.feePerTransactionTypeCd = this.mcForm.getValue('ctrlNonFactoringPerTransactionType') === 'percentage'
          ? EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_PERCENTAGE : EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT;
        this.ebFactoringClone.feePerBatch = this.mcForm.getValue('ctrlNonFactoringPerBatchValue');
        this.ebFactoringClone.percentageForInitialPayoutNonFactoring = this.mcForm.getValue('ctrlSplitValue');
        this.ebFactoringClone.noDaysForInitialPayoutNonFactoring = this.mcForm.getValue('ctrlToBePaidOutDaysAfterWithdrawal');
        this.ebFactoringClone.noDaysForTheRestToBePaidNonFactoring = this.mcForm.getValue('ctrlTheRestToBePaid');
      }

      this.ebFactoringClone.sendClaimsToBlacklistFlg = this.mcForm.getValue('ctrlSendClaimsToBlacklist') === 'yes';
      this.ebFactoringClone.removeMemberWithoutOpenDebtFromBlFlg = this.mcForm.getValue('ctrlRemoveMemberFromBlacklist') === 'yes' && this.ebFactoringClone.sendClaimsToBlacklistFlg

      if (this.ebFactoringClone.sendClaimsToBlacklistFlg) {
        this.ebFactoringClone.blacklistTriggerSoftBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterSoft');
        this.ebFactoringClone.blacklistTriggerHardBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterHard');
      }

      const maximalNumberOfFailedWithdrawals = this.mcForm.getValue('ctrlMaximalNumberOfFailedWithdrawals');
      const perTransactionLimit = Number(this.mcForm.getValue('ctrlPerTransactionLimitValue'));
      const perSepaRunLimit = Number(this.mcForm.getValue('ctrlPerSepaRunLimitValue'));
      const perCurrentEntityLimit = Number(this.mcForm.getValue('ctrlCurrentEntityLimitValue'));
      const cumulativeLimit = Number(this.mcForm.getValue('ctrlCumulativePerEntityAndSubentitiesLimitValue'));
      const softBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterSoft');
      const hardBounceCount = this.mcForm.getValue('ctrlSentToBlacklistAfterHard');
      if (this.mcForm.getValue('ctrlContractEndDate') && (this.ebFactoring.contractEndDate < this.ebFactoring.contractStartDate)) {
        this.mcForm.getControl('ctrlContractEndDate')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlContractEndDate')?.get('errorMsg')?.setValue(this.mcGod.t('cc.out-factoring.end-date-need-to-be-after-start-date'));
      }
      if ((perSepaRunLimit && perTransactionLimit) && (perSepaRunLimit < perTransactionLimit)) {
        this.mcForm.getControl('ctrlPerSepaRunLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlPerSepaRunLimitValue')?.get('errorMsg')?.setValue(this.mcGod.t('cc.contract.per-sepa-run-limit-needs-to-be-higher-than-sepa-per-transaction-limit'));
      }
      if ((perCurrentEntityLimit && perTransactionLimit) && (perCurrentEntityLimit < perTransactionLimit)) {
        this.mcForm.getControl('ctrlCurrentEntityLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlCurrentEntityLimitValue')?.get('errorMsg')?.setValue(McGod.t('cc.contract.per-current-entity-limit-needs-to-be-higher-than-per-transaction-limit'));
      }
      if ((perCurrentEntityLimit && cumulativeLimit) && (cumulativeLimit < perCurrentEntityLimit)) {
        this.mcForm.getControl('ctrlCumulativePerEntityAndSubentitiesLimitValue')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlCumulativePerEntityAndSubentitiesLimitValue')?.get('errorMsg')?.setValue(McGod.t('cc.contract.cumulative-limit-needs-to-be-higher-than-per-transaction-limit'));
      }
      if (softBounceCount && maximalNumberOfFailedWithdrawals && (softBounceCount > maximalNumberOfFailedWithdrawals)) {
        this.mcForm.getControl('ctrlSentToBlacklistAfterSoft')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlSentToBlacklistAfterSoft')?.get('errorMsg')?.setValue(McGod.t('cc.contract.number-of-soft-bounce-failed-withdrawals-has-to-be-smaller-than-maximal-number-of-failed-withdrawals'));
      }
      if (hardBounceCount && maximalNumberOfFailedWithdrawals && (hardBounceCount > maximalNumberOfFailedWithdrawals)) {
        this.mcForm.getControl('ctrlSentToBlacklistAfterHard')?.get('errorFlg')?.setValue(true);
        this.mcForm.getControl('ctrlSentToBlacklistAfterHard')?.get('errorMsg')?.setValue(McGod.t('cc.contract.number-of-hard-bounce-failed-withdrawals-has-to-be-smaller-than-maximal-number-of-failed-withdrawals'));
      }
    }
  }

}
