import { Component,Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EntityRegistrationService } from '@miticon-ui/mc-core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-mc-entity-registration',
  templateUrl: './mc-entity-registration.component.html',
  styleUrl: './mc-entity-registration.component.scss',
})
export class McEntityRegistrationComponent {
  landingBg: string;
  logo: string;
  selectedLanguage = 'en';
  panelOpenState: boolean = false;
  companyInfoForm: FormGroup;

  languagesList = [
    {
      name: 'English',
      abbreviation: 'en',
    },
    {
      name: 'Deutsch',
      abbreviation: 'de',
    },
  ];

  constructor(
    @Inject('designConfig') design: any,
    private translate: TranslateService,
    private router: Router,
    private entityRegistrationService: EntityRegistrationService,
    private toastr: ToastrService,
  ) {
    this.landingBg = `url('${design.landingBg}')`;
    this.logo = `url('${design.logoMark}')`;
    this.translate.setDefaultLang(this.selectedLanguage);
  }

  getFormData(): any {
    return {
      mcEntityDto: {
        name: this.companyInfoForm.get('companyName')?.value,
        abbreviation: this.companyInfoForm.get('abbreviation')?.value,
        companyForm: this.companyInfoForm.get('legalForm')?.value,
        address: this.companyInfoForm.get('address')?.value,
        country: this.companyInfoForm.get('country')?.value,
        description: this.companyInfoForm.get('description')?.value,
        impressum: this.companyInfoForm.get('impressum')?.value,

        euVat: this.companyInfoForm.get('vatNumber')?.value,
        tax: this.companyInfoForm.get('taxNumber')?.value,
        ustId: this.companyInfoForm.get('ustId')?.value,
        bankNameOverride: this.companyInfoForm.get('bankNameOverride')?.value,
        externalId: this.companyInfoForm.get('externalId')?.value,

        email: this.companyInfoForm.get('generalEmail')?.value,
        phone: this.companyInfoForm.get('phoneNumber')?.value,
        fax: this.companyInfoForm.get('faxNumber')?.value,

        additionalData: {
          accountingMattersEmail: this.companyInfoForm.get('accountingEmail')?.value,
          managementEmail: this.companyInfoForm.get('managementEmail')?.value,
          billingEmail: this.companyInfoForm.get('billingEmail')?.value,
        },

        studioOwner: this.companyInfoForm.get('studioOwner')?.value,
        studioOwnerAddress: this.companyInfoForm.get('studioOwnerAddress')?.value,
        studioOwnerPostalCode: this.companyInfoForm.get('studioOwnerPostalCode')?.value,
        studioOwnerCity: this.companyInfoForm.get('studioOwnerCity')?.value,
        studioManager: this.companyInfoForm.get('studioManager')?.value,
        responsiblePerson: this.companyInfoForm.get('responsiblePerson')?.value,
        responsiblePersonFunction: this.companyInfoForm.get('responsiblePersonFunction')?.value,

        legalRepresentative: this.companyInfoForm.get('legalRepresentative')?.value,
        legalRepresentativeEmail: this.companyInfoForm.get('legalRepresentativeEmail')?.value,
        courtInCharge: this.companyInfoForm.get('courtInCharge')?.value,

        webDomain: this.companyInfoForm.get('domain')?.value,

        contractType: this.companyInfoForm.get('contractType')?.value,
      },

      mcBankAccountDto: {
        accountOwner: this.companyInfoForm.get('accountOwner')?.value,
        bankName: this.companyInfoForm.get('bankName')?.value,
        bankCity: this.companyInfoForm.get('bankCity')?.value,
        bankCountry: this.companyInfoForm.get('bankCountry')?.value,
        accountNumber: this.companyInfoForm.get('accountNumber')?.value,
        blz: this.companyInfoForm.get('blz')?.value,
        swift: this.companyInfoForm.get('swift')?.value,
        iban: this.companyInfoForm.get('iban')?.value,
      },

      invitationCode: this.companyInfoForm.get('invitationCode')?.value,

      country: this.companyInfoForm.get('country')?.value,
      subdomain: this.companyInfoForm.get('subdomain')?.value,

      flgFactoring: this.companyInfoForm.get('contractType')?.value === 'Factoring',
      flgService: this.companyInfoForm.get('contractType')?.value === 'Service',

      generalTerms: this.companyInfoForm.get('generalTerms')?.value ?? false,
      priceListTerms: this.companyInfoForm.get('pricelistTerms')?.value ?? false,
    };
  }

  onRegister() {
    const body = this.getFormData();
    this.entityRegistrationService.registerEntity(body).subscribe({
      next: () => {
        this.router.navigate(['/registration/success'], {
          state: {
            selectedLanguage: this.selectedLanguage,
          },
        });
      },
      error: (error: any) => {
        this.toastr.error(error.error.message ? error.error.message : error);
      },
    });
  }

  onChooseLanguage(language: any) {
    if (this.selectedLanguage !== language.toLowerCase()) {
      this.selectedLanguage = language;
      this.translate.use(language);
    }
  }
}
