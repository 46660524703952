import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {EbFactoring, McBoolean, McCurrencyService, McEntity2Service, McGod, CurrencyUtil} from '@miticon-ui/mc-core';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@miticon-ui/mc-components';

const enum DialogAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_ACCEPT = 'btn-primary'
}

const enum DialogRevokeAction {
  ACT_CANCEL = 'btn-secondary',
  ACT_REVOKE = 'btn-primary'
}



@Component({
  selector: 'app-shared-factoring-profile-part',
  templateUrl: './app-shared-factoring-profile-part.component.html',
  styleUrls: ['./app-shared-factoring-profile-part.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppSharedFactoringProfilePartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() ebFactoringId!: number;
  @Input() ebFactoring = new EbFactoring();
  @Input() contractType!: string;
  @Input() statusMyPendingMcb = new McBoolean();
  @Input() statusNotMyPendingMcb = new McBoolean();
  @Input() statusOutPendingMcb = new McBoolean();
  @Input() statusNotOutPendingMcb = new McBoolean();
  @Input() showRevokeContractDialogMcb = new McBoolean();
  @Input() idChildEntity!: number;
  revokeDialogButtonsKvm = new Map<string, string>();
  @Output() eventAccepted = new EventEmitter();
  @Output() eventRejected = new EventEmitter();
  @Output() eventCanceled = new EventEmitter();
  @Output() eventRevoked = new EventEmitter();
  public showRejectMcb = new McBoolean();
  public acceptDialogButtonsKvm = new Map<string, string>();
  public mcGod = McGod.getInstance();
  destroyed$ = new Subject();
  currencyName!: string | undefined;

  // ---------------------------------------------------------------------
  constructor(
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private currencyService: McCurrencyService,
    private dialog: MatDialog,) {}
  // ---------------------------------------------------------------------
  ngOnInit() {
    this.renderer.removeClass(document.body, 'mc-overflow-hidden');
    this.acceptDialogButtonsKvm.set('btn-primary', this.mcGod.t('cc.factoring.accept'));
    this.acceptDialogButtonsKvm.set('btn-secondary', this.mcGod.t('cc.common.edit.cancel'));

    this.revokeDialogButtonsKvm.set(DialogRevokeAction.ACT_REVOKE, this.mcGod.t('cc.factoring.revoke'));
    this.revokeDialogButtonsKvm.set(DialogRevokeAction.ACT_CANCEL, this.mcGod.t('cc.common.edit.cancel'));
  }

  // ---------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ebFactoringId']) {
      if ((changes['ebFactoringId'].previousValue !== changes['ebFactoringId'].currentValue) && (changes['ebFactoringId'].currentValue > 0)) {
       this.actLoad();
      }
    }
  }
  // ---------------------------------------------------------------------
  public actLoad(): void {
    this.ebFactoring = new  EbFactoring();
    this.ebFactoring.loadById(this.ebFactoringId, () => {
      this.currencyService.getById(this.ebFactoring.idMcCurrency)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(res => {
          this.currencyName = res.name;
        });
    });
  }

  getContractCancellationPeriod() {
    if (this.ebFactoring.contractCancellationPeriod > 1) {
      return `${this.ebFactoring.contractCancellationPeriod} ${this.mcGod.t('cc.my-factoring.months')}`;
    } else if (this.ebFactoring.contractCancellationPeriod === 1) {
      return `${this.ebFactoring.contractCancellationPeriod} ${this.mcGod.t('cc.my-factoring.month')}`;
    } else {
      return '0 months';
    }
  }

  getBlacklistTriggerSoftBounceCount() {
    return `${this.ebFactoring.blacklistTriggerSoftBounceCount} ${this.mcGod.t('cc.contract.soft-bounce-failed-withdrawals')}`;
  }

  getBlacklistTriggerHardBounceCount() {
    return `${this.ebFactoring.blacklistTriggerHardBounceCount} ${this.mcGod.t('cc.contract.hard-bounce-failed-withdrawals')}`;
  }

  getHardLimitForCurrentEntityInLastDays() {
    return `${this.format(this.ebFactoring.hardLimitForCurrentEntity)} ${this.mcGod.t('cc.contract.in-last')} ${this.ebFactoring.hardLimitForCurrentEntityDays} ${this.mcGod.t('cc.factoring.day-s')}`;
  }

  getHardCumulativeLimitInLastDays() {
    if (this.ebFactoring.hardCumulativeLimit >= 0) {
      return `${this.format(this.ebFactoring.hardCumulativeLimit)} ${this.mcGod.t('cc.contract.in-last')} ${this.ebFactoring.hardCumulativeLimitDays} ${this.mcGod.t('cc.factoring.day-s')}`;
    } else {
      return '';
    }

  }

  getMonthsAllowedPastCount() {
    if (this.ebFactoring.monthsAllowedPastCount === 1) {
      return `${this.ebFactoring.monthsAllowedPastCount} ${this.mcGod.t('cc.common.date.months')}`;
    } else {
      return `${this.ebFactoring.monthsAllowedPastCount} ${this.mcGod.t('cc.common.date.months')}`;
    }
  }

  getMonthsAllowedFutureCount() {
    if (this.ebFactoring.monthsAllowedFutureCount === 1) {
      return `${this.ebFactoring.monthsAllowedFutureCount} ${this.mcGod.t('cc.common.date.months')}`;
    } else {
      return `${this.ebFactoring.monthsAllowedFutureCount} ${this.mcGod.t('cc.common.date.months')}`;
    }
  }

  openAcceptContractDialog(){
    this.dialog.open(ConfirmationDialogComponent,{
      height: '230px',
      autoFocus: false,
      restoreFocus: false,
      data: {
        header: 'cc.my-factoring.accept-contract',
        description: this.mcGod.t('cc.my-factoring.are-you-sure-you-want-to-accept-this-contract')+'?',
        cancelBtnLabel: 'cc.common.edit.cancel',
        confirmBtnLabel: 'cc.factoring.accept'
      }
    }).afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.eventAccepted.emit();
      }
    })
  }

  actRejectContract() {
    this.showRejectMcb.setTrue();
  }

  onRejectModalCanceled() {
    this.showRejectMcb.setFalse();
  }

  actCancelContract() {
    this.eventCanceled.emit();
  }

  onContractRejected() {
    this.showRejectMcb.setFalse();
    this.eventCanceled.emit();
  }

  onMcDialogBoxRevokeContractActionButtonClicked(action: string) {
    if (action === DialogRevokeAction.ACT_CANCEL) {
      this.showRevokeContractDialogMcb.setFalse();
    } else {
      this.eventRevoked.emit();
      this.showRevokeContractDialogMcb.setFalse();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getPerTransactionFeeString() {
    if (this.ebFactoring.feePerTransactionTypeCd === EbFactoring.FEE_PER_TRANSACTION_TYPE_CD_AMOUNT) {
      return this.format(this.ebFactoring.feePerTransaction);
    } else {
      return `${this.ebFactoring.feePerTransaction} %`;
    }
  }

  format(value: number): string {
    return CurrencyUtil.format(value, this.ebFactoring.mcCurrency.code);
  }
}


